const AUTH_TOKEN_KEYS = {
  id: "id",
  authToken: "authToken",
  client: "client",
  uid: "uid",
  tokenType: "tokenType",
  expiry: "expiry",
};
const PREFIX = "patch-";

// Utility to get and set the keys in localStorage

// Get a token value by key
export const getToken = (key: string): string | null => {
  return localStorage.getItem(key);
};

// Set a token value by key
export const setToken = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

// Remove a token by key
export const removeToken = (key: string): void => {
  localStorage.removeItem(key);
};

// Get all token data
export const getAllTokens = (): Record<string, string | null> => {
  return {
    id: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.id),
    authToken: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.authToken),
    client: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.client),
    uid: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.uid),
    tokenType: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.tokenType),
    expiry: localStorage.getItem(PREFIX + AUTH_TOKEN_KEYS.expiry),
  };
};

// Set all tokens at once
export const setAllTokens = (tokenData: {
  id: string;
  authToken: string;
  client: string;
  uid: string;
  tokenType: string;
  expiry: string;
}): void => {
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.id, tokenData.id);
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.authToken, tokenData.authToken);
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.client, tokenData.client);
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.uid, tokenData.uid);
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.tokenType, tokenData.tokenType);
  localStorage.setItem(PREFIX + AUTH_TOKEN_KEYS.expiry, tokenData.expiry);
};

// Clear all tokens
export const clearAllTokens = (): void => {
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.id);
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.authToken);
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.client);
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.uid);
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.tokenType);
  localStorage.removeItem(PREFIX + AUTH_TOKEN_KEYS.expiry);
};

export const authHeaders = () => {
  const tokens = getAllTokens();

  return {
    "access-token": tokens.authToken || "",
    client: tokens.client || "",
    uid: tokens.uid || "",
    expiry: tokens.expiry || "",
    tokenType: tokens.tokenType || "",
  };
};
