import { useNavigate } from "react-router-dom";
import StatusBadge from "@/components/telemedicine-call-status-badge";

const Row = ({ telemedicineCall }) => {
  const navigate = useNavigate();

  const onSelect = (telemedicineCallId) => {
    navigate(`/telemedicine/${telemedicineCallId}`);
  };

  // friendlyAppointmentScheduledAt
  // patientName
  // userFullName

  return (
    <tr
      key={telemedicineCall.id}
      className="cursor-pointer"
      onClick={() => onSelect(telemedicineCall.id)}
    >
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {telemedicineCall.friendlyScheduledAt}
            </div>
            {/* <div className="font-medium text-gray-900">{transcription.patientName}</div> */}
            {/* <div className="font-medium text-gray-500 truncate">{transcription.friendlyAppointmentScheduledAt}</div> */}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {telemedicineCall.hospitalName}
            </div>
            <div className="font-medium text-gray-500 truncate w-56">
              {telemedicineCall.accountName}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {telemedicineCall.friendlyName}
            </div>
            {/* <div className="font-medium text-gray-900">{transcription.patientName}</div> */}
            {/* <div className="font-medium text-gray-500 truncate">{transcription.friendlyAppointmentScheduledAt}</div> */}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 w-36">
              {telemedicineCall.scheduledByUserFullName}
            </div>
            {/* <div className="font-medium text-gray-500 truncate w-96">{transcription.revisedContent || transcription.transcribedContent}</div> */}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 w-36">
              {telemedicineCall.userFullName}
            </div>
            {/* <div className="font-medium text-gray-500 truncate w-96">{transcription.revisedContent || transcription.transcribedContent}</div> */}
          </div>
        </div>
      </td>
      {/* <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{transcription.friendlyUploadedDate}</div>
            <div className="font-medium text-gray-500 truncate w-40">{transcription.friendlyUploadedTime}</div>
          </div>
        </div>
      </td>
      */}
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <StatusBadge status={telemedicineCall.status} />
      </td>
    </tr>
  );
};
const List = ({ telemedicineCalls }) => {
  if (!telemedicineCalls) {
    return null;
  }
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Scheduled At
          </th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Hospital
          </th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Title
          </th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Scheduled By
          </th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Appointment With
          </th>
          {/* <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Content</th> */}
          {/* <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Uploaded At</th> */}
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
            Status
          </th>
          {/* <th className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
              </th> */}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {telemedicineCalls.map((telemedicineCall) => (
          <Row key={telemedicineCall.id} telemedicineCall={telemedicineCall} />
        ))}
      </tbody>
    </table>
  );
};

export default List;
