import BackgroundScreen from "@/components/BackgroundScreen";
import Spinner from "@/components/Spinner";
import { useAuthentication } from "@/contexts/authentication";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SignOut() {
  const navigate = useNavigate();
  const { logAdminUserOut, logUserOut, currentAdminUser, currentUser } =
    useAuthentication();

  const handleLogouts = async () => {
    console.log({ currentAdminUser, currentUser });

    await logAdminUserOut();

    await logUserOut();

    navigate("/");
  };

  React.useEffect(() => {
    handleLogouts();
  }, []);

  return (
    <BackgroundScreen>
      <Spinner />
    </BackgroundScreen>
  );
}
