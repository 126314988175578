import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_TELEMEDICINE_CALL_MUTATION,
  GET_DOCTOR_USERS_QUERY,
  GET_WEEKLY_APPOINTMENTS_QUERY,
} from "./queries";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useHospitals } from "@/contexts/hospital";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectValue,
  SelectTrigger,
  SelectContent,
} from "@/components/ui/select";
import { Appointment, User } from "@/pages/admin/types";

const NewTelemedicineCallModal = ({
  refetch,
  open,
  setIsOpen,
}: {
  refetch: () => void;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const [temporaryName, setTemporaryName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showManualInputBox, setShowManualInputBox] = useState<boolean>(true);
  const [appointmentDatetime, setAppointmentDatetime] = useState<Date>(
    new Date(),
  );
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const { currentHospital } = useHospitals();

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  const [createAppointment, _] = useMutation(
    CREATE_TELEMEDICINE_CALL_MUTATION,
    {
      errorPolicy: "all",
      onCompleted: () => {
        refetch();
        setTemporaryName("");
        setUserId("");
        setAppointmentDatetime(new Date());
        setIsVisible(false);
        setShowManualInputBox(true);
      },
      variables: {
        temporaryName,
        userId,
        appointmentDatetime,
        appointmentId,
      },
    },
  );

  const { data } = useQuery(GET_DOCTOR_USERS_QUERY, {
    fetchPolicy: "no-cache",
  });

  const isIntegrationEnabled = currentHospital?.integrationEnabled;

  const { data: appointmentsData } = useQuery(GET_WEEKLY_APPOINTMENTS_QUERY, {
    fetchPolicy: "no-cache",
    skip: !isIntegrationEnabled,
  });

  useEffect(() => {
    const hasAppointments =
      appointmentsData?.weeklyAppointments &&
      appointmentsData?.weeklyAppointments?.length > 0;

    if (isIntegrationEnabled && hasAppointments) {
      setShowManualInputBox(false);
    } else {
      setShowManualInputBox(true);
    }
  }, [currentHospital, appointmentsData]);

  if (!isVisible) {
    return null;
  }

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const onAppointmentSelect = (value: string) => {
    if (value === "new") {
      setShowManualInputBox(true);
    } else {
      setShowManualInputBox(false);
      setAppointmentId(value);
      const appointment = appointmentsData?.weeklyAppointments.find(
        (appointment: Appointment) => appointment.id === value,
      );
      if (appointment) {
        setAppointmentDatetime(new Date(appointment.scheduledAt));
      }
    }
  };

  // Organize appointments by date
  const groupedAppointments: Record<
    string,
    {
      value: string;
      label: string;
    }[]
  > =
    appointmentsData?.weeklyAppointments.reduce(
      (
        groups: Record<string, { value: string; label: string }[]>,
        appointment: Appointment,
      ) => {
        const date = appointment.friendlyDate;
        groups[date] = groups[date] || [];
        groups[date].push({
          value: appointment.id,
          label: `${appointment.friendlyTime}: ${appointment.patientName}`,
        });
        return groups;
      },
      {},
    ) ?? {};

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex flex-col sm:items-start w-full">
              <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                <h3
                  className="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                >
                  New Telemedicine Call
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Schedule a call now for the doctor to join at appointment
                    time.
                  </p>
                </div>
              </div>
              <div className="flex flex-col px-4 w-full">
                {showManualInputBox && (
                  <div className="mb-4">
                    <label
                      htmlFor="appointmentId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Appointment
                    </label>
                    <div>
                      <Select
                        name="appointmentId"
                        onValueChange={(value) => onAppointmentSelect(value)}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select an appointment" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(groupedAppointments).map(
                            ([date, values]) => {
                              return (
                                <SelectGroup>
                                  <SelectLabel>{date}</SelectLabel>
                                  {values.map((item) => {
                                    return (
                                      <SelectItem value={item.value}>
                                        {item.label}
                                      </SelectItem>
                                    );
                                  })}
                                </SelectGroup>
                              );
                            },
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                )}
                {showManualInputBox && (
                  <div className="mb-4">
                    <label
                      htmlFor="temporaryName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div>
                      <Input
                        value={temporaryName}
                        onChange={(e) => setTemporaryName(e.target.value)}
                        id="temporaryName"
                        name="temporaryName"
                        required
                        placeholder="Fluffy 2pm Dr Jones"
                      />
                    </div>
                  </div>
                )}
                <div className="mb-4">
                  <label
                    htmlFor="userId"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Doctor
                  </label>
                  <div>
                    {/* <Select
                      defaultValue={[]}
                      name="userId"
                      onChange={({ value }) => setUserId(value)}
                      options={data?.doctorUsers.map((user) => ({
                        value: user.id,
                        label: user.emailSignature,
                      }))}
                    /> */}
                    {/* Migrates react select to shadcn */}
                    <Select
                      name="userId"
                      value={userId}
                      onValueChange={(value) => setUserId(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a doctor" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {data?.doctorUsers.map((user: User) => (
                            <SelectItem value={user.id}>
                              {user.emailSignature}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="scheduledAt"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Scheduled Time
                  </label>
                  <div>
                    <DatePicker
                      selected={appointmentDatetime}
                      onChange={(datetime: Date) =>
                        setAppointmentDatetime(datetime)
                      }
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      timeIntervals={15}
                      filterTime={filterPassedTime}
                      showTimeSelect
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:flex sm:pl-4">
              <button
                onClick={() => {
                  createAppointment();
                }}
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setIsVisible(false);
                  setIsOpen(false);
                  setShowManualInputBox(true);
                }}
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTelemedicineCallModal;
