import { useQuery } from "@apollo/client";
import { GET_TRANSCRIPTION_AUDIO_QUERY } from "./queries";

const AudioWidget = ({ transcriptionId }) => {
  const { data } = useQuery(GET_TRANSCRIPTION_AUDIO_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: transcriptionId,
    },
  });

  if (!data?.adminTranscriptionAudio) {
    return null;
  }

  return (
    <div className="mt-8">
      <audio controls="controls">
        <source src={data.adminTranscriptionAudio} type="audio/mp3" />
      </audio>
    </div>
  );
};

export default AudioWidget;
