import { useMutation } from "@apollo/client";
import { Account } from "@/pages/admin/types";
import { ADMIN_CREATE_USER_MUTATION } from "./queries";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  SelectGroup,
} from "@/components/ui/select";

import { default as SelectReact } from "react-select";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import Spinner from "@/components/Spinner";

// Enum for user types
enum Kind {
  Manager = "manager",
  Doctor = "doctor",
  Nurse = "nurse",
  Other = "other",
}

// Zod schema for form validation
const newUserSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  postNominal: z.string().optional(),
  email: z.string().email("Invalid email format"),
  phone: z.string().min(1, "Phone number is required"),
  kind: z.nativeEnum(Kind),
  hospitalIds: z
    .array(z.string())
    .min(1, "Please select at least one hospital"),
  analyticsAccess: z.boolean(),
  sendEmail: z.boolean(),
});

type NewUserFormData = z.infer<typeof newUserSchema>;

const NewUserDialog = ({
  refetch,
  account,
  handleClose,
}: {
  refetch: () => void;
  account: Account;
  handleClose: () => void;
}) => {
  const form = useForm<NewUserFormData>({
    resolver: zodResolver(newUserSchema),
  });

  const { errors } = form.formState;
  const { toast } = useToast();

  const [createUser, { error: createUserError, loading }] = useMutation(
    ADMIN_CREATE_USER_MUTATION,
    {
      errorPolicy: "all",
      onCompleted: () => {
        toast({
          title: "User created",
          variant: "default",
        });
        refetch();
        form.reset();
        handleClose();
      },
    },
  );

  const onSubmit = (data: NewUserFormData) => {
    createUser({
      variables: {
        accountId: account.id,
        hospitalIds: data.hospitalIds,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        postNominal: data.postNominal,
        kind: data.kind,
        phone: data.phone,
        sendEmail: data.sendEmail,
        analyticsAccess: data.analyticsAccess,
      },
    });
  };

  const hospitalOptions = account.hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  return (
    <DialogContent
      onClick={(e) => e.preventDefault()}
      className="max-h-[800px] flex flex-col"
    >
      <DialogHeader>
        <DialogTitle>New User</DialogTitle>
      </DialogHeader>
      <div className="flex-1 overflow-y-scroll">
        <Form {...form}>
          <form
            className="flex flex-col max-w-full m-2 space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Jane" />
                  </FormControl>
                  <FormMessage>{errors.firstName?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Doe" />
                  </FormControl>
                  <FormMessage>{errors.lastName?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="doctor@hospital.com" />
                  </FormControl>
                  <FormMessage>{errors.email?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="phone"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="503-380-5300" />
                  </FormControl>
                  <FormMessage>{errors.phone?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="kind"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Role</SelectLabel>
                          <SelectItem value={Kind.Manager}>Manager</SelectItem>
                          <SelectItem value={Kind.Doctor}>Doctor</SelectItem>
                          <SelectItem value={Kind.Nurse}>Nurse</SelectItem>
                          <SelectItem value={Kind.Other}>Other</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage>{errors.kind?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="hospitalIds"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Hospital</FormLabel>
                  <FormControl>
                    <SelectReact
                      className="w-full"
                      isMulti
                      options={hospitalOptions}
                      onChange={(selected) =>
                        field.onChange(selected.map((option) => option.value))
                      }
                    />
                  </FormControl>
                  <FormMessage>{errors.hospitalIds?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="analyticsAccess"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex items-start gap-2">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                  <FormLabel>Has Analytics Access?</FormLabel>
                </FormItem>
              )}
            />

            <FormField
              name="sendEmail"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex items-start gap-2">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                  <FormLabel>Send Email?</FormLabel>
                </FormItem>
              )}
            />

            <div className="mt-6 flex justify-between">
              <Button
                disabled={loading}
                onClick={form.handleSubmit(onSubmit)}
                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
              >
                {loading ? <Spinner /> : "Submit"}
              </Button>
              <Button
                type="button"
                onClick={handleClose}
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
              >
                Cancel
              </Button>
            </div>
            {/* Display error */}
            {createUserError && (
              <div className="text-red-500 text-sm">
                {createUserError.message}
              </div>
            )}
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};

export default NewUserDialog;
