import { gql } from "@apollo/client";

export const ADMIN_CREATE_USER_MUTATION = gql`
  mutation AdminCreateUser(
    $accountId: ID!
    $hospitalIds: [ID!]!
    $email: String!
    $firstName: String!
    $lastName: String!
    $kind: String!
    $phone: String
    $sendEmail: Boolean!
    $analyticsAccess: Boolean!
  ) {
    adminCreateUser(
      accountId: $accountId
      hospitalIds: $hospitalIds
      email: $email
      firstName: $firstName
      lastName: $lastName
      kind: $kind
      phone: $phone
      sendEmail: $sendEmail
      analyticsAccess: $analyticsAccess
    ) {
      success
    }
  }
`;
