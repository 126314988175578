import MainLayout from "@/components/doctor/main-layout";
import Content from "./content";
import EmailLeftBar from "@/components/doctor/email-left-bar";

const EmailThreadPage = () => {
  return (
    <MainLayout>
      <div
        className="flex flex-row h-100 w-full"
        style={{ height: "calc(100vh - 4rem)" }}
      >
        <EmailLeftBar />
        <Content />
      </div>
    </MainLayout>
  );
};

export default EmailThreadPage;
