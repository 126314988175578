import { gql } from "@apollo/client";

export const GET_EMAIL_THREAD_QUERY = gql`
  query EmailThread($emailThreadId: ID!) {
    emailThread(emailThreadId: $emailThreadId) {
      id
      googleId
      correspondent
      snippet
      latestSentAt
      latestIsInbound
      isRead
      subject
      labels
      friendlyLatestSentAt
      correspondentEmail

      emailMessages {
        id
        googleId
        snippet
        sentAt
        friendlySentAt
        to
        from
        cc
        bcc
        subject
        labels
        text
        html
        attachments
        presignedAttachmentUrls
      }
    }
  }
`;
