import { gql } from "@apollo/client";

export const ADMIN_CREATE_HOSPITAL_MUTATION = gql`
  mutation AdminCreateHospital(
    $accountId: ID!
    $name: String!
    $timezone: String!
    $website: String!
    $address1: String!
    $city: String!
    $state: String!
    $zip: String!
  ) {
    adminCreateHospital(
      accountId: $accountId
      name: $name
      timezone: $timezone
      website: $website
      address1: $address1
      city: $city
      state: $state
      zip: $zip
    ) {
      success
    }
  }
`;
