const EmailMessage = ({ message }) => {
  return (
    <div className="border border-slate-200 rounded-md px-8 py-4 mt-4 mb-10">
      <div className="flex flex-row items-start justify-between mb-8 border-b pb-4">
        <div className="flex flex-col items-start">
          <p className="text-sm">
            <span className="font-medium">From:</span> {message.from}
          </p>
          <p className="text-sm">
            <span className="font-medium">To:</span> {message.to}
          </p>
          {message.cc.length > 0 && (
            <p className="text-sm">
              <span className="font-medium">CC:</span> {message.to}
            </p>
          )}
          {message.bcc.length > 0 && (
            <p className="text-sm">
              <span className="font-medium">BCC:</span> {message.to}
            </p>
          )}
        </div>
        <p className="text-sm">{message.friendlySentAt}</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: message.html }}></div>
      {message.attachments.length > 0 && (
        <div className="w-100 flex flex-row mt-8">
          {message.attachments.map((attachment) => (
            // <div className='h-40 w-32 bg-slate-200 flex flex-col justify-end p-2'>
            //   <span className='truncate text-xs font-medium'>{attachment.filename}</span>
            // </div>
            <div className="flex flex-row items-center">
              <svg
                className="h-4 w-4 inline mr-2"
                data-slot="icon"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M18.97 3.659a2.25 2.25 0 0 0-3.182 0l-10.94 10.94a3.75 3.75 0 1 0 5.304 5.303l7.693-7.693a.75.75 0 0 1 1.06 1.06l-7.693 7.693a5.25 5.25 0 1 1-7.424-7.424l10.939-10.94a3.75 3.75 0 1 1 5.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 0 1 5.91 15.66l7.81-7.81a.75.75 0 0 1 1.061 1.06l-7.81 7.81a.75.75 0 0 0 1.054 1.068L18.97 6.84a2.25 2.25 0 0 0 0-3.182Z"
                ></path>
              </svg>
              <a
                target="_blank"
                href={message.presignedAttachmentUrls[attachment.key]}
                className="font-semibold text-sm hover:underline cursor-pointer"
                rel="noreferrer"
              >
                {attachment.filename}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmailMessage;
