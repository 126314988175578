import { gql } from "@apollo/client";

export const GET_CURRENT_HOSPITAL_QUERY = gql`
  query GetCurrentHospital {
    currentHospital {
      id
      name
      timezone
      integrationEnabled
      integrationProvider
    }

    hospitals {
      id
      name
    }
  }
`;
