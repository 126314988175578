import { gql } from "@apollo/client";

export const GET_ADMIN_TRANSCRIPTION_QUERY = gql`
  query GetAdminTranscription($transcriptionId: ID!) {
    adminTranscription(transcriptionId: $transcriptionId) {
      id
      filename
      status
      uploadedAt
      updatedAt
      transcribedContent
      revisedContent
      soapContent
      proofreadContent
      friendlyUploadedAt
      clientEmailContent
      temporaryName
      friendlyName

      proofreaderUserFullName
      hospitalName
      userFullName
    }
  }
`;

// patientName
// friendlyAppointmentScheduledAt
