import { gql } from "@apollo/client";

export const GET_TELEMEDICINE_CALLS_LIST = gql`
  query GetTelemedicineCalls($filter: String, $offset: Int, $limit: Int) {
    telemedicineCalls(filter: $filter, offset: $offset, limit: $limit) {
      id
      temporaryName
      friendlyName
      friendlyScheduledAt
      userFullName
      scheduledByUserFullName
      hospitalName
      accountName
      status
    }

    telemedicinePages(filter: $filter, limit: $limit)
  }
`;
