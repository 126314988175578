import { gql } from "@apollo/client";

export const GET_ADMIN_TRANSCRIPTION_LIST = gql`
  query GetAdminTranscriptions($filter: String, $offset: Int, $limit: Int) {
    adminTranscriptions(filter: $filter, offset: $offset, limit: $limit) {
      id
      filename
      status
      uploadedAt
      updatedAt
      transcribedContent
      revisedContent
      proofreadContent
      friendlyUploadedDate
      friendlyUploadedTime
      friendlyName
      temporaryName
      userFullName
      hospitalName
      accountName
    }

    adminTranscriptionPages(filter: $filter, limit: $limit)
  }
`;

// friendlyAppointmentScheduledAt
// patientName
