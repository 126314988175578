import { gql } from "@apollo/client";

export const GET_TELEMEDICINE_CALL_QUERY = gql`
  query GetTelmedicineCall($callId: ID!) {
    telemedicineCall(callId: $callId) {
      id
      friendlyScheduledAt
      temporaryName
      friendlyName
      userFullName
      scheduledByUserFullName
      hospitalName
      accountName
      dyteDoctorToken
      dyteId
      status
      clientIsOnCall
      expired

      transcription {
        id
      }
    }
  }
`;

// friendlyAppointmentScheduledAt
// patientName
