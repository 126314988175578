import { useQuery } from "@apollo/client";
import StatusBadge from "@/components/admin-transcription-status-badge";
import MainLayout from "@/components/admin/main-layout";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AudioWidget from "./audio-widget";
import ContentTab from "./content-tab";
import EmailTab from "./email-tab";
import { GET_ADMIN_TRANSCRIPTION_QUERY } from "./queries";
import SoapTab from "./soap-tab";

const AdminTranscriptionPage = () => {
  const [currentTab, setCurrentTab] = useState("soap");

  const { transcriptionId } = useParams();

  const { data, refetch } = useQuery(GET_ADMIN_TRANSCRIPTION_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      transcriptionId: transcriptionId,
    },
  });

  useEffect(() => {
    const pusher = new Pusher("d10606873286053e4a22", {
      cluster: "us3",
    });

    const channel = pusher.subscribe(`transcription-${transcriptionId}`);

    channel.bind("updated", function (data) {
      refetch();
    });

    return () => channel.unbind("updated");
  }, []);

  if (!data) {
    return null;
  }

  return (
    <MainLayout>
      <div className="p-8">
        <Link
          to="/admin/records"
          className="text-xs text-indigo-500 hover:text-indigo-600"
        >
          &larr; Back
        </Link>
        <div className="flex flex-row items-end justify-start">
          {/* <h1 className='text-xl font-bold mr-4'>Appointment with "{data.adminTranscription.patientName}"</h1> */}
          <h1 className="text-xl font-bold mr-4">
            {data.adminTranscription.friendlyName}
          </h1>
          <div>
            <StatusBadge status={data.adminTranscription.status} />
          </div>
        </div>
        <h3 className="text-medium font-medium text-slate-500">
          {data.adminTranscription.friendlyUploadedAt}
        </h3>
        {/* <h3 className='text-medium font-medium text-slate-500'>{data.adminTranscription.friendlyAppointmentScheduledAt}</h3> */}
        <br />
        <p className="text-slate-600">
          <span className="font-medium text-slate-800">Recorded At</span>:{" "}
          {data.adminTranscription.hospitalName}
        </p>
        <p className="text-slate-600">
          <span className="font-medium text-slate-800">Recorded By</span>:{" "}
          {data.adminTranscription.userFullName}
        </p>
        {data.adminTranscription.proofreaderUserFullName && (
          <p className="text-slate-600">
            <span className="font-medium text-slate-800">Proofread By</span>:{" "}
            {data.adminTranscription.proofreaderUserFullName}
          </p>
        )}
        <AudioWidget transcriptionId={transcriptionId} />
        <br />
        <div>
          {/* <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">Select a tab</label>
            {/* <!-- Use an "onChange" listener to redirect the user to the selected tab URL. --> */}
          {/* <select id="tabs" name="tabs" className="block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
              <option>Transcription</option>
              <option>SOAP</option>
            </select> */}
          {/* </div> */}
          <div className="sm:block">
            <div className="border-b border-slate-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {/* <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700" --> */}
                <a
                  onClick={() => setCurrentTab("soap")}
                  className={`${
                    currentTab == "soap"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700"
                  } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}
                >
                  SOAP
                </a>
                <a
                  onClick={() => setCurrentTab("email")}
                  className={`${
                    currentTab == "email"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700"
                  } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}
                >
                  Client Email
                </a>
                <a
                  onClick={() => setCurrentTab("transcription")}
                  className={`${
                    currentTab == "transcription"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700"
                  } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}
                >
                  Transcription
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="py-8">
          {currentTab == "soap" && (
            <SoapTab
              transcription={data.adminTranscription}
              refetch={refetch}
            />
          )}
          {currentTab == "email" && (
            <EmailTab
              transcription={data.adminTranscription}
              refetch={refetch}
            />
          )}
          {currentTab == "transcription" && (
            <ContentTab
              transcription={data.adminTranscription}
              refetch={refetch}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminTranscriptionPage;
