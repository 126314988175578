import { useQuery } from "@apollo/client";
import { GET_TRANSCRIPTION_AUDIO_QUERY } from "./queries";
import WaveformAudioPlayer from "@/components/waveform-audio-player";

const AudioWidget = ({ transcriptionId }) => {
  const { data, error } = useQuery(GET_TRANSCRIPTION_AUDIO_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: transcriptionId,
    },
  });

  console.log("audioWidget aduio", data?.transcriptionAudio);

  if (!data?.transcriptionAudio) {
    return null;
  }

  return (
    // <WaveformAudioPlayer audioUrl={data.transcriptionAudio} />
    <div className="mt-8">
      <audio controls="controls">
        <source src={data.transcriptionAudio} type="audio/mp4" />
      </audio>
    </div>
  );

  // return (
  //
  // )
};

export default AudioWidget;
