import Header from "@/components/doctor/header";

const MainLayout = ({ children }) => {
  return (
    <div className="min-h-full bg-white">
      <Header />
      <div>{children}</div>
    </div>
  );
};

export default MainLayout;
