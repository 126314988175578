import { gql } from "@apollo/client";

export const LOGIN_USER_MUTATION = gql`
  mutation LoginUser(
    $email: String!
    $password: String!
    $rememberMe: Boolean!
    $platform: String!
  ) {
    loginUser(
      email: $email
      password: $password
      rememberMe: $rememberMe
      platform: $platform
    ) {
      id
      authToken
      client
      uid
      tokenType
      expiry
    }
  }
`;
