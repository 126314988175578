import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADMIN_CREATE_ACCOUNT_MUTATION } from "./queries";
import { Input } from "@/components/ui/input";

const NewAccountModal = ({
  refetch,
  open,
  handleClose,
}: {
  refetch: () => Promise<void>;
  open: boolean;
  handleClose: () => void;
}) => {
  const [name, setName] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  const [createAccount] = useMutation(ADMIN_CREATE_ACCOUNT_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      console.log(data);
      refetch();
      setName("");
      setIsVisible(false);
    },
    variables: {
      name,
    },
  });

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex flex-col sm:items-start w-full">
              <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                <h3
                  className="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                >
                  New account
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Accounts are business entities. This is how we bill. It can
                    have multiple hospitals under it.
                  </p>
                </div>
              </div>
              <div className="flex flex-col px-4 w-full">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 mt-20 sm:flex sm:pl-4">
              <button
                onClick={() => createAccount()}
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
              >
                Submit
              </button>
              <button
                onClick={() => handleClose()}
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAccountModal;
