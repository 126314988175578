import { useQuery } from "@apollo/client";
import { GET_PATIENT_CONTEXT_QUERY } from "./queries";

const Patient = ({ patientId, setContext, clientId }) => {
  const { data, loading, error, refetch } = useQuery(
    GET_PATIENT_CONTEXT_QUERY,
    {
      fetchPolicy: "no-cache",
      variables: {
        patientId: patientId,
      },
    },
  );

  console.log("patientId", patientId);
  console.log("patient data", data);

  if (loading) {
    return null;
  }
  if (!data && !loading) {
    return (
      <div
        className="border-l shadow-lg bg-white h-full py-4 px-4 h-full bg-white"
        style={{ width: "40rem" }}
      >
        <p className="text-slate-600 text-xs mb-4">PIMS Information</p>
        <p className="text-slate-600 text-sm">No information found</p>
      </div>
    );
  }

  return (
    <div
      className="border-l shadow-lg bg-white h-full py-4 px-4 h-full bg-white"
      style={{ width: "40rem" }}
    >
      <p className="text-slate-600 text-xs mb-4">PIMS Information</p>
      <p className="text-xl">
        {data.patientContext.vetdataName}
        {data.patientContext.vetdataIsDeceased && (
          <span className="text-gray-400">
            {" "}
            (Deceased {data.patientContext.friendlyVetdataDateOfDeath})
          </span>
        )}
        {data.patientContext.vetdataInactive && (
          <span className="text-gray-400"> (Inactive)</span>
        )}
        {data.patientContext.vetataDeleted && (
          <span className="text-red-400"> (Deleted)</span>
        )}
      </p>
      <p className="text-base mb-4 text-slate-600">
        {data.patientContext.vetdataAge}yo{" "}
        {data.patientContext.vetdataSpeciesGenderDescription}{" "}
        {data.patientContext.vetdataSpeciesDescription}{" "}
        {data.patientContext.vetdataSpeciesBreedDescription}
      </p>
      <p className="text-sm mb-4">
        Birthdate: {data.patientContext.friendlyVetdataDateOfBirth}
      </p>
      <div className="mt-4">
        <p className="font-bold text-sm">Clients</p>
        {data.patientContext.clients.map((client) =>
          client.id == clientId ? (
            <p
              onClick={() => setContext("client")}
              className="cursor-pointer text-blue-400 text-sm"
            >
              {client.vetdataFirstName} {client.vetdataLastName}
            </p>
          ) : (
            <p className="text-sm">
              {client.vetdataFirstName} {client.vetdataLastName}
            </p>
          ),
        )}
      </div>
      <div className="mt-4">
        <p className="font-bold text-sm">Alerts</p>
        {data.patientContext.patientAlerts.length == 0 && (
          <p className="text-slate-600 text-sm">No alerts</p>
        )}
        {data.patientContext.patientAlerts.map((alert) => (
          <p className="text-sm">
            {alert.friendlyVetdataDate}: {alert.vetdataValue}
          </p>
        ))}
      </div>
      <div className="mt-4">
        <p className="font-bold text-sm">Notes</p>
        {data.patientContext.patientNotes.length == 0 && (
          <p className="text-slate-600 text-sm">No notes</p>
        )}
        {data.patientContext.patientNotes.map((note) => (
          <p className="text-sm">
            {note.friendlyVetdataDate}: {note.vetdataValue}
          </p>
        ))}
      </div>
      <div className="mt-4">
        <p className="font-bold text-sm">Vaccinations</p>
        {data.patientContext.vaccinations.length == 0 && (
          <p className="text-slate-600 text-sm">No vaccinations</p>
        )}
        {data.patientContext.vaccinations.map((vaccination) => (
          <p className="text-sm">
            {vaccination.friendlyVetdataDateGiven}:{" "}
            {vaccination.vetdataDescription} (Expires{" "}
            {vaccination.friendlyVetdataExpirationDate})
          </p>
        ))}
      </div>
      <div className="mt-4">
        <p className="font-bold text-sm">Prescriptions</p>
        {data.patientContext.prescriptions.length == 0 && (
          <p className="text-slate-600 text-sm">No prescriptions</p>
        )}
        {data.patientContext.prescriptions.map((prescription) => (
          <p className="text-sm">
            {prescription.friendlyVetdataIssuedDate}:{" "}
            {prescription.vetdataDescription}{" "}
            {prescription.vetdataQuantity && `x${prescription.vetdataQuantity}`}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Patient;
