import { gql } from "@apollo/client";

export const GET_ADMIN_ACCOUNTS_LIST = gql`
  query GetAdminAccounts {
    adminAccounts {
      id
      name
      userCount
      hospitalCount
      mrr
      licenseCount
      status
      friendlyCustomerSince
    }
  }
`;
