import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ADMIN_CREATE_HOSPITAL_MUTATION } from "./queries";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const schema = z.object({
  name: z.string().min(1, "Name is required"),
  timezone: z.enum([
    "Pacific Time (US & Canada)",
    "Arizona",
    "Mountain Time (US & Canada)",
    "Central Time (US & Canada)",
    "Eastern Time (US & Canada)",
  ]),
  website: z.string().min(1, "Website is required"),
  address1: z.string().min(1, "Address is required"),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, "State is required"),
  zip: z.string().min(1, "Zip code is required"),
});

const NewHospitalModal = ({
  refetch,
  open,
  accountId,
  handleClose,
}: {
  refetch: () => void;
  open: boolean;
  accountId: string;
  handleClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      timezone: "Pacific Time (US & Canada)",
      website: "",
      address1: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const [createHospital, _] = useMutation(ADMIN_CREATE_HOSPITAL_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch();
      reset(); // Reset the form after successful submission
      handleClose();
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    console.log(data);
    createHospital({ variables: { accountId, ...data } });
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col sm:items-start w-full">
                <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    New Hospital
                  </h3>
                </div>
                <div className="flex flex-col px-4 w-full">
                  {/* Name Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div>
                      <Input
                        {...register("name")}
                        id="name"
                        name="name"
                        placeholder="Pasadena Pet Hospital"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.name && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Timezone Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="timezone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Timezone
                    </label>
                    <div>
                      <Select {...register("timezone")}>
                        <SelectTrigger id="timezone">
                          <SelectValue placeholder="Select timezone" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Pacific Time (US & Canada)">
                            Pacific Time (US & Canada)
                          </SelectItem>
                          <SelectItem value="Arizona">Arizona</SelectItem>
                          <SelectItem value="Mountain Time (US & Canada)">
                            Mountain Time (US & Canada)
                          </SelectItem>
                          <SelectItem value="Central Time (US & Canada)">
                            Central Time (US & Canada)
                          </SelectItem>
                          <SelectItem value="Eastern Time (US & Canada)">
                            Eastern Time (US & Canada)
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      {errors.timezone && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.timezone.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Website Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="website"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Website
                    </label>
                    <div>
                      <Input
                        {...register("website")}
                        id="website"
                        name="website"
                        placeholder="website.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.website && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.website.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Address Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="address1"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <div>
                      <Input
                        {...register("address1")}
                        id="address1"
                        name="address1"
                        placeholder="123 Main St"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.address1 && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.address1.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* City Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <div>
                      <Input
                        {...register("city")}
                        id="city"
                        name="city"
                        placeholder="Pasadena"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.city && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.city.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* State Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      State
                    </label>
                    <div>
                      <Input
                        {...register("state")}
                        id="state"
                        name="state"
                        placeholder="CA"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.state && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.state.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Zip Field */}
                  <div className="mt-4">
                    <label
                      htmlFor="zip"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Zip
                    </label>
                    <div>
                      <Input
                        {...register("zip")}
                        id="zip"
                        name="zip"
                        placeholder="93293"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-blue-400 sm:text-sm sm:leading-6"
                      />
                      {errors.zip && (
                        <p className="text-red-600 text-sm mt-1">
                          {errors.zip.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 mt-20 sm:flex sm:pl-4">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2
                    text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    reset(); // Reset form fields when cancelling
                    handleClose();
                  }}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2
                    text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                    hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHospitalModal;
