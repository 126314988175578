import { useEffect } from "react";
import { useDyteClient } from "@dytesdk/react-web-core";
import { DyteMeeting } from "@dytesdk/react-ui-kit";
import { useParams } from "react-router-dom";

const VideoCall = () => {
  const { token } = useParams();
  const [meeting, initMeeting] = useDyteClient();

  useEffect(() => {
    if (token) {
      initMeeting({
        authToken: token,
        defaults: {
          audio: true,
          video: true,
        },
      });
    }
  }, [token]);

  return <DyteMeeting meeting={meeting} />;
};

export default VideoCall;
