import { useState } from "react";
import Compose from "./compose";

const ActionBar = ({ emailThread, refetchThread }) => {
  const [action, setAction] = useState("menu");

  const returnToActionBar = () => {
    setAction("menu");
  };

  if (action === "menu") {
    return (
      <div className="w-full flex flex-row">
        <button
          onClick={() => setAction("reply")}
          type="button"
          className="mr-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Reply
        </button>
        <button
          onClick={() => setAction("forward")}
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Forward
        </button>
      </div>
    );
  }

  if (action === "reply") {
    return (
      <Compose
        emailThread={emailThread}
        composeMode="reply"
        returnToActionBar={returnToActionBar}
        refetchThread={refetchThread}
      />
    );
  }
  if (action === "forward") {
    return (
      <Compose
        emailThread={emailThread}
        composeMode="forward"
        returnToActionBar={returnToActionBar}
        refetchThread={refetchThread}
      />
    );
  }
};

export default ActionBar;
