import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Pagination = ({ pages }) => {
  const [searchParams, _] = useSearchParams();

  const page = parseInt(searchParams.get("page")) || 0;

  const numPages = Math.min(pages, 10);

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-8 pb-8">
      <div className="-mt-px flex w-0 flex-1">
        {page != 0 && (
          <Link to={`/telemedicine?page=${page - 1}`}>
            <span className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
              <svg
                className="mr-3 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                  clipRule="evenodd"
                />
              </svg>
              Previous
            </span>
          </Link>
        )}
      </div>

      <div className="hidden md:-mt-px md:flex">
        {Array(numPages)
          .fill(0)
          .map((_, i) => (
            <Link key={i} to={`/telemedicine?page=${i}`}>
              <span
                className={`${page == i ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"} inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium`}
              >
                {i + 1}
              </span>
            </Link>
          ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {page != numPages - 1 && (
          <Link to={`/telemedicine?page=${page + 1}`}>
            <span className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
              Next
              <svg
                className="ml-3 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
