import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN_USER_MUTATION } from "./queries";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useAuthentication } from "@/contexts/authentication";
import PatchIconLogoWhite from "@/assets/patch-icon-logo-white.png";
import { useQuery } from "@tanstack/react-query";
import { setAllTokens } from "../../../api/utils";
import { getCurrentUser } from "../../../api/auth.api";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";

const LoginPage = () => {
  const { logUserIn, currentUser } = useAuthentication();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/records");
    }
  });

  // Queries
  const { data } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      return getCurrentUser();
    },
  });

  console.log({ data });

  const [loginUser, { error }] = useMutation(LOGIN_USER_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e);
    },
    onCompleted: (data) => {
      console.log(data);

      const { authToken, client, id, uid, expiry, tokenType } = data.loginUser;

      // Store tokens in localStorage (or use cookies)

      setAllTokens({
        authToken,
        client,
        id,
        expiry,
        tokenType,
        uid,
      });

      // Log the user in and redirect
      logUserIn(data.loginUser);

      navigate("/records");
    },
    variables: {
      email,
      password,
      rememberMe,
      platform: "transcription-frontend",
    },
  });

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    loginUser();
  };

  const onIconClick = () => {
    navigate("/admin/login");
  };

  return (
    <div className="h-screen w-full bg-slate-800">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            onClick={onIconClick}
            className="mx-auto h-16 w-auto"
            src={PatchIconLogoWhite}
            alt="Patch Education"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Checkbox
                    value={String(rememberMe)}
                    onChange={() => setRememberMe((prev) => !prev)}
                    id="remember-me"
                    name="remember-me"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <Link
                    to={"/doctor/forgot-password"}
                    className="font-semibold text-blue-400 hover:opacity-80"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44]"
                >
                  Sign in
                </button>
              </div>
              {error && (
                <div className="w-full flex flex-col items-center">
                  <p className="text-red-500 text-sm">
                    Incorrect email or password
                  </p>
                </div>
              )}
            </form>
          </div>
          {/* 
          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            {VITE_REACT_APP_ENVIRONMENT == 'development' && <a href="/checkout?productIds=10dc13cc-396a-412f-8803-f3a0134235b0" className="font-semibold leading-6 text-[#FF3F44] hover:opacity-80 ml-2">Sign up</a>}
            {VITE_REACT_APP_ENVIRONMENT == 'production' && <a href="/checkout?productIds=0fae2960-1712-4315-a9c7-c691f06ab18a" className="font-semibold leading-6 text-[#FF3F44] hover:opacity-80 ml-2">Sign up</a>}
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
