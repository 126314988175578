import { useState } from "react";
import NewHospitalModal from "./new-hospital-modal";
import { Account, Hospital } from "@/pages/admin/types";

const Row = ({ hospital }: { hospital: Hospital }) => {
  return (
    <tr key={hospital.id}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{hospital.name}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {hospital.fullAddress}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{hospital.timezone}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{hospital.website}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {hospital.doctorCount}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">
              {hospital.userCount}
            </div>
          </div>
        </div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <StatusBadge status={account.status} />
      </td> */}
    </tr>
  );
};

const HospitalsTab = ({
  account,
  refetch,
}: {
  account: Account;
  refetch: () => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  console.log("modalOpen", modalOpen);

  if (account.hospitals.length == 0) {
    return (
      <>
        <div className="flex flex-row justify-between items-center">
          <p>No hospitals yet</p>
          <button
            onClick={() => setModalOpen(true)}
            className="rounded-md px-4 py-2 bg-blue-500 text-white font-medium"
          >
            Add Hospital
          </button>
        </div>
        <NewHospitalModal
          open={modalOpen}
          refetch={refetch}
          accountId={account.id}
          handleClose={() => {
            setModalOpen(false);
          }}
        />
      </>
    );
  }
  return (
    <div className="flex">
      <div className="max-w-full flex-1 flex flex-col  py-2 align-middle">
        <div className="flex flex-row-reverse">
          <button
            onClick={() => setModalOpen(true)}
            className="rounded-md px-4 py-2 bg-blue-500 text-white font-medium"
          >
            Add Hospital
          </button>
        </div>
        <table className="flex-1 max-w-full divide-y divide-gray-300 overflow-auto">
          <thead>
            <tr>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Name
              </th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Address
              </th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Timezone
              </th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Website
              </th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Doctor Count
              </th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                User Count
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {account.hospitals.map((hospital) => (
              <Row key={hospital.id} hospital={hospital} />
            ))}
          </tbody>
        </table>
      </div>
      <NewHospitalModal
        open={modalOpen}
        refetch={refetch}
        accountId={account.id}
        handleClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};

export default HospitalsTab;
