const StatusBadge = ({ status }: { status: string }) => {
  if (status == "active") {
    return (
      <div className="flex flex-row">
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">
          {status}
        </span>
      </div>
    );
  }
  if (status == "trial") {
    return (
      <div className="flex flex-row">
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 capitalize">
          {status}
        </span>
      </div>
    );
  }
  if (status == "canceled") {
    return (
      <div className="flex flex-row">
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20 capitalize">
          {status}
        </span>
      </div>
    );
  }
  return null;
};

export default StatusBadge;
