import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { COMPLETE_TRANSCRIPTION_MUTATION } from "./queries";

const EmailTab = ({ transcription, refetch }) => {
  const [email, setEmail] = useState();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setEmail(transcription.clientEmailContent);
  }, [transcription]);

  const [completeTranscription, _] = useMutation(
    COMPLETE_TRANSCRIPTION_MUTATION,
    {
      errorPolicy: "all",
    },
  );

  const onComplete = () => {
    completeTranscription({
      variables: {
        transcriptionId: transcription.id,
      },
      onCompleted: () => {
        console.log("complete");
        refetch();
        // setCurrentTranscription(null)
        // refetchList()
      },
    });
  };

  const copyNoteToClipboard = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);
  };

  return (
    <div>
      <textarea
        className={`w-full mb-4 rounded-lg border-gray-300 ${(transcription.status == "Generating SOAP" || transcription.status == "Generating Email") && "bg-gray-200"}`}
        placeholder="Edit Client Email here..."
        value={email}
        disabled={
          transcription.status == "Generating SOAP" ||
          transcription.status == "Generating Email"
        }
        rows={15}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div>
        <button
          onClick={copyNoteToClipboard}
          className="text-white bg-black px-4 py-2 rounded-md mr-2"
        >
          Click to Copy
        </button>
        {transcription.status != "completed" && (
          <button
            onClick={onComplete}
            className="text-white bg-black px-4 py-2 rounded-md mr-2"
          >
            Mark Complete
          </button>
        )}
        {/* <button onClick={onSaveAndPublish} className='text-white bg-black px-4 py-2 rounded-md'>Save + Publish</button> */}
      </div>
      {copied && <p className="text-green-700 font-medium text-sm">Copied!</p>}
    </div>
  );
};

export default EmailTab;
