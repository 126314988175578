import { gql } from "@apollo/client";

export const GET_ANALYTICS_FILTERS = gql`
  query AnalyticsFilters($hospitalId: String) {
    analyticsFilters(hospitalId: $hospitalId) {
      timePeriods
      doctors {
        name
        id
      }
      hospitals {
        name
        id
      }
    }
  }
`;

export const GET_ANALYTICS = gql`
  query Analytics($hospitalId: String, $timePeriod: String, $doctorId: String) {
    analytics(
      hospitalId: $hospitalId
      timePeriod: $timePeriod
      doctorId: $doctorId
    ) {
      id
      problems {
        name
        percent
        occurrences
        recommendations {
          name
          percent
          occurrences
          rejected
        }
      }
    }
  }
`;
