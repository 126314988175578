import { useQuery } from "@apollo/react-hooks";
import { noop } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

import PatchIconLogoWhite from "../../assets/patch-icon-logo-white.png";

import { useToast } from "@/hooks/use-toast";
import { useMutation } from "@tanstack/react-query";
import {
  logAdminUserOut as logAdminUserOutApi,
  logUserOut as logUserOutApi,
} from "../../api/auth.api";
import { clearAllTokens } from "../../api/utils";
import BackgroundScreen from "../../components/BackgroundScreen";
import Spinner from "../../components/Spinner";
import { User } from "../../pages/admin/types";
import {
  removeLocalStorageEntry,
  setLocalStorageEntry,
} from "../../utils/local-storage";
import {
  GET_CURRENT_ADMIN_USER_QUERY,
  GET_CURRENT_USER_QUERY,
} from "./queries";

export const ADMIN_USER_LOCALSTORAGE_KEY = "_transcription_admin_user";
export const DOCTOR_USER_LOCALSTORAGE_KEY = "_transcription_doctor_user";

const AuthenticationContext = createContext<
  | {
      currentUser: User | null;
      currentAdminUser: User | null;
      logAdminUserIn: (user: User) => void;
      logAdminUserOut: () => Promise<void>;
      logUserIn: (user: User) => void;
      logUserOut: () => Promise<void>;
      adminLoading: boolean;
      doctorLoading: boolean;
      fetchUser: () => Promise<void>;
    }
  | undefined
>({
  currentUser: null,
  currentAdminUser: null,
  logAdminUserIn: () => Promise.resolve(),
  logAdminUserOut: () => Promise.resolve(),
  logUserIn: () => Promise.resolve(),
  logUserOut: () => Promise.resolve(),
  adminLoading: true,
  doctorLoading: true,
  // @ts-expect-error - we don't need an async here
  fetchUser: noop,
});

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const { toast } = useToast();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentAdminUser, setCurrentAdminUser] = useState<User | null>(null);

  const { loading: adminLoading } = useQuery(GET_CURRENT_ADMIN_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentAdminUser(data?.currentAdminUser);
    },
  });

  const { loading: doctorLoading, refetch } = useQuery(GET_CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentUser(data?.currentUser);
    },
  });

  const logAdminUserIn = useCallback((user: User) => {
    setLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id,
    });
    removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
    setCurrentAdminUser(user);
    setCurrentUser(null);
  }, []);

  const logoutAdminUserMutation = useMutation({
    mutationFn: async () => {
      setCurrentAdminUser(null);
      try {
        await logAdminUserOutApi();
      } catch (error) {
        console.log(error);
      } finally {
        toast({
          title: "Logged out",
          variant: "default",
        });
        clearAllTokens();
        removeLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
      }
    },
  });

  const logUserIn = useCallback((user: User) => {
    setLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id,
    });
    removeLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
    setCurrentUser(user);
    setCurrentAdminUser(null);
  }, []);

  const logoutUserMutation = useMutation({
    mutationFn: async () => {
      try {
        await logUserOutApi();
      } catch (error) {
        console.log(error);
      } finally {
        clearAllTokens();
        removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
        setCurrentUser(null);
      }
    },
    onSettled: () => {
      // Sets admin user null
      toast({
        title: "Logged out",
        variant: "default",
      });
      removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
    },
  });

  if (adminLoading || doctorLoading) {
    return (
      <BackgroundScreen>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-16 w-auto"
              src={PatchIconLogoWhite}
              alt="Patch"
            />
            <div className="h-[120px]">
              <div className="pt-10" />
              <Spinner size="medium" className="text-white" />
            </div>
          </div>
        </div>
      </BackgroundScreen>
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        currentAdminUser,
        fetchUser: async () => {
          await refetch();
        },
        logAdminUserIn,
        logAdminUserOut: logoutAdminUserMutation.mutateAsync,
        logUserIn,
        logUserOut: logoutUserMutation.mutateAsync,
        adminLoading,
        doctorLoading,
        // refetch
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error("AuthWrapper is missing");
  }
  return context;
};

export const AdminAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentAdminUser, adminLoading } = useAuthentication();

  if (!adminLoading && !currentAdminUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (adminLoading) {
    return null;
  }

  return children;
};

export const DoctorAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser, doctorLoading } = useAuthentication();

  if (!doctorLoading && !currentUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (doctorLoading) {
    return null;
  }

  return children;
};

export const AnalyticsAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser, doctorLoading } = useAuthentication();

  if (!doctorLoading && !currentUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (doctorLoading) {
    return null;
  }

  if (currentUser?.hasAnalytics) {
    return children;
  } else {
    // user is not authenticated
    return <Navigate to="/" />;
  }
};

export { AuthenticationContext, useAuthentication };
export default AuthWrapper;
